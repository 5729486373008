import React from 'react';
import ReactJoin from 'react-join';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis';

import { Stock } from 'services';
import { Badge, DateFormat } from 'components/atoms';
import { useMediaQuery } from 'beautiful-react-hooks';
import { isNotNil } from '../../../../../../utils/fp';

import styles from './styles.module.css';

type Props = Stock;

export const PromotionCard = ({
  id,
  is_active,
  title,
  end_at,
  start_at,
  logo,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  return (
    <div className={styles.wrapper} key={id}>
      <div className={styles.left}>
        {is_active && (
          <Badge size="medium" color="#20CB6A">
            активна
          </Badge>
        )}
        {!is_active && (
          <Badge size="medium" color="#DD6465">
            не активна
          </Badge>
        )}
        <h2 className={styles.title}>
          <LinesEllipsis
            text={title?.trim()}
            maxLine={isLarge ? 4 : 2}
            ellipsis="..."
            trimRight
          />
        </h2>

        <div className={styles.date}>
          <ReactJoin separator={<span>—</span>}>
            {[
              start_at ? (
                <DateFormat value={start_at} format="dateMonthYearShort" />
              ) : null,
              end_at ? (
                <DateFormat value={end_at} format="dateMonthYearShort" />
              ) : null,
            ].filter(isNotNil)}
          </ReactJoin>
        </div>
      </div>
      <div className={styles.right}>
        {logo?.url && (
          <img
            className={styles.imagePreview}
            src={logo?.url}
            alt={title || 'promotion preview'}
          />
        )}
      </div>
    </div>
  );
};
