import React, { ReactNode } from 'react';
import cc from 'classcat';
import { getIndentClasses, IndentsProps } from 'components/atoms';
import styles from './styles.module.css';
import stylesSize from './size.module.css';
import stylesVariants from './variants.module.css';

/** @todo: создать новый компонент BadgeButton */
/** @todo: создать новый компонент IconButton */
/** @todo: стили для иконки и бэйджа из этого компонента убрать */
export type ButtonBaseProps = {
  children?: ReactNode;
  variant?: 'text' | 'contained';
  color?:
    | 'transparent'
    | 'default'
    | 'disable'
    | 'primary'
    | 'normal'
    | 'alert';
  size?: 'small' | 'medium' | 'large';
  icon?: ReactNode;
  badge?: ReactNode;
  iconRight?: boolean;
  className?: string;
  textClassName?: string;
  tagName?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  [k: string]: any;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  IndentsProps;

export const ButtonBase = ({
  children,
  icon,
  badge,
  iconRight,
  color = 'default',
  variant = 'contained',
  size = 'medium',
  className,
  tagName,
  indent,
  fullWidth,
  textClassName,
  isSelected,
  ...rest
}: ButtonBaseProps) =>
  React.createElement(
    tagName || 'button',
    {
      ...(!tagName ? { type: 'button' } : {}),
      ...rest,
      className: cc([
        'baseButton',
        styles.baseButton,
        {
          [styles.selected]: isSelected,  
        },
        {
  
          [stylesVariants.transparent]: color === 'transparent',
          [stylesVariants.default]: color === 'default',
          [stylesVariants.disable]: color === 'disable',
          [stylesVariants.primary]: color === 'primary',
          [stylesVariants.normal]: color === 'normal',
          [stylesVariants.alert]: color === 'alert',
        },
        {
          [stylesSize.small]: size === 'small',
          [stylesSize.medium]: size === 'medium',
          [stylesSize.large]: size === 'large',
        },
        {
          [stylesVariants.text]: variant === 'text',
        },
        className,
        getIndentClasses(indent),
        {
          [styles.fullWidth]: fullWidth,
        },
      ]),
    },
    <>
      {!iconRight && icon && (
        <span
          className={cc({
            [styles.iconWrapperLeft]: children,
          })}
        >
          {icon}
        </span>
      )}
      {children && <span className={textClassName}>{children}</span>}
      {iconRight && icon && (
        <div
          className={cc({
            [styles.iconWrapperRight]: children,
          })}
        >
          {icon}
        </div>
      )}
      {badge && <div className={styles.badgeWrapper}>{badge}</div>}
    </>,
  );
