import { ComplexRaw, StatsGroupsItem } from "services";
import { CollapseHeader, CollapseWrap, Counter, Info, Title } from "./QueuesBlock.styled";
import { VillageDataProps } from "context";
import { Box } from "components/styledComponents/Box";
import { filteredMax, filteredMin, formatPrice } from "../../utils";
import { pluralize } from "utils/pluralize";
import { BoxSwiper } from "./components/BoxSwiper";

type CollapseBoxProps = {
  name: string;
  statsData?: StatsGroupsItem;
  complexId?: string;
  data: VillageDataProps[string]
};

export const VilligeTypeHouseList: React.FC<CollapseBoxProps> = ({
  statsData,
  data,
  name,
}) => {

  const { squareMin } = statsData || {};

  if (!data.data.length) return null
  
  return (
    <CollapseWrap>
      <CollapseHeader>
        <Box display={'flex'} alignContent={"center"} flexDirection={"column"} style={{gap: '4px'}}>
          <Title style={{ fontSize: '28px'}}>{name}</Title>
          <Box className='pompon' style={{paddingTop: '4px'}}>
            <Info>от {Math.round(Number(filteredMin(data, 'square' as keyof ComplexRaw))) || squareMin} м2</Info>
            <Info>
              {formatPrice(Number(filteredMin(data, 'price' as keyof ComplexRaw)))}
              {' - '}
              {formatPrice(Number(filteredMax(data, 'price' as keyof ComplexRaw)))}
               
              </Info>
          </Box>
        </Box>
        <Counter>
          {pluralize(data?.data?.length, ['объект', 'объекта', 'объектов'])}
        </Counter>
      </CollapseHeader>

      <Box display="flex" flexDirection="column" py="20px">          
        <BoxSwiper boxes={data?.data}></BoxSwiper>
      </Box>
    </CollapseWrap>)
};

