import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './styles.module.css';
import { ComplexRaw } from 'services';
import SwiperCore from 'swiper';

export const SwiperPlan = ({ box }: { box: ComplexRaw }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  //свайпер не всегда корректно высчитывает ширину SwiperSlide - поэтому необходим update
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, [box]);
  
  if (!box || !box.layouts.length) {
    return null; 
  }

  return (
    <Swiper
      id='boxSwiperPicture'
      spaceBetween={4}
      slidesPerView={1}
      className={styles.swiper}
      watchOverflow
      autoplay={{ delay: 1000, disableOnInteraction: true }}
      nested
      slideToClickedSlide
      pagination={{ clickable: true }}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      {box.layouts.map(({ photo, id }) => {
        return <SwiperSlide key={id} >
          <div className={styles.swiperCard}  >
            <img src={photo} alt={id} className={styles.image}/>
          </div>
          <div className={styles.pagination}/>
        </SwiperSlide>
      })}
    </Swiper>
  );
};
