import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Section, getAllColsSizes, getMaxRows } from 'services';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import styles from './styles.module.css';
import { MayBe } from '../../../../../../types';
import { scrollBarStore } from '../../ScrollBarStore';
import { gridApartmentsStylesStore } from '../../stores/GridApartmentsStylesStore';
import { rootApartmentStore } from '../../../../../../stores/Apartment/RootApartmentStore';

type Props = {
  children: ReactNode;
  sections: MayBe<Section[]>;
};

const GridContainerTestIDs = {
  wrapper: 'GridContainer_wrapper',
};

export const GridContainerRender = ({ children, sections }: Props) => {
  const isLarge = useMediaQuery('(min-width: 768px)');
  const { apartmentUIStore } = rootApartmentStore;

  useEffect(() => {
    if (isLarge) {
      gridApartmentsStylesStore.setLargeValues();
    } else {
      gridApartmentsStylesStore.setSmallValues();
    }
  }, [isLarge]);

  const {
    FLOOR_SIDEBAR_WIDTH,
    APARTMENT_VIEW_WIDTH,
    SECTIONS_COLUMN_HEIGHT,
    SECTIONS_TITLE_HEIGHT,
  } = gridApartmentsStylesStore;

  const maxRows: ReturnType<typeof getMaxRows> = useMemo(
    () => getMaxRows(sections),
    [sections],
  );
  const headersRowsCount = 2;

  /**
   * rowsCount = (count header's rows) + (max rows in matrix)
   * */
  const rowsCount: number = headersRowsCount + (maxRows || 0);

  const colsSizes = useMemo(() => getAllColsSizes(sections), [sections]);

  const columns = useMemo(
    () =>
      `${FLOOR_SIDEBAR_WIDTH}px repeat( ${colsSizes.length}, minmax(min-content, max-content))`,
    [FLOOR_SIDEBAR_WIDTH, colsSizes],
  );

  const getTemplateRows = useCallback(
    (rowsCount: number): string =>
      `${SECTIONS_TITLE_HEIGHT}px ${SECTIONS_COLUMN_HEIGHT}px repeat(${rowsCount}, minmax(min-content, max-content))`,
    [],
  );
  const scrollBarRef = useRef<any>();

  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarStore.setSimpleBarInstance(scrollBarRef.current);
    }
  }, []);

  return (
    <div
      {...dataTestIdToProps(GridContainerTestIDs.wrapper)}
      ref={scrollBarRef}
      className={styles.scrollWrapper}
    >
      <div className={styles.scroll}>
        <div
          className={styles.wrapper}
          style={{
            gridTemplateColumns: columns,
            gridTemplateRows: getTemplateRows(rowsCount),
          }}
        >
          {children}
          {/**
           Это офсет для возможности скролить к элементам в самом конце сетки
           без него, активные элементы сетки могут быть спрятаны под сайдбаром
           */}
          {apartmentUIStore.activeApartment && isLarge && (
            <div
              style={{
                width: APARTMENT_VIEW_WIDTH,
                gridRowStart: 3,
                gridColumnStart: columns.length,
                gridRowEnd: rowsCount + 1,
                gridColumnEnd: columns.length + 1,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const GridContainer = observer(GridContainerRender);
