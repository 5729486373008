import React, { VFC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { size } from 'lodash/fp';
import {
  getFirstBuildingId,
  Params,
  Complex,
  ComplexRaw,
} from 'services';
import { observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';
import { Box } from 'components/styledComponents/Box';
import { typeHouseContext, VillageDataProps, } from 'context/TypeHouseProvider';
import { Block } from '../../components/Block';
import { ApartmentQueuesFilter } from './components/ApartmentQueuesFilter';
import { QueueBuildingsFilter } from './components/QueueBuildingsFilter';
import styles from './styles.module.css';
import { buildingGridStore } from '../../../LayoutPage/stores/buildingGridStore';
import { VillageTypeHouseFilter } from './components/VillageTypeHouseFilter';
import { VilligeTypeHouseList } from './components/VilligeTypeHouseList/VilligeTypeHouseList';
import { EmptyData, LinkButton } from 'components/atoms';

const  isAllDataEmpty = (obj: VillageDataProps | undefined) =>  {
  for (let key in obj) {
      if (!Array.isArray(obj[key].data) || obj[key].data.length !== 0) {
          return false;
      }
  }
  return true;
}

type Props = {
  apartmentsCount: number;
  isVillage: boolean;
  statsGroups: ComplexRaw['statsGroups'];
} & Pick<Complex, 'buildings'>;

export const QueuesBlock: VFC<Props> = observer(({
  apartmentsCount,
  buildings,
  isVillage,
  statsGroups,
}): JSX.Element => {

  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  const context = useContext(typeHouseContext);
  const villageData = context?.villageData;
  const villageDataFilter = context?.villageDataFilter;
  const handleResetVillageFilter = context?.handleResetVillageFilter; 

  const currentBuilding = buildingGridStore.gridBordObject?.id
    ? buildingGridStore.gridBordObject?.id.toString()
    : getFirstBuildingId()(buildings)?.toString();

  const title = isVillage
    ? `${pluralize(apartmentsCount, [
        'типовой проект',
        'типового проекта',
        'типовых проектов',
      ])} в продаже`
    : `${pluralize(apartmentsCount, [
        'квартира',
        'квартиры',
        'квартир',
      ])} в продаже в ${pluralize(size(buildings), [
        'корпусе',
        'корпусах',
        'корпусов',
      ])}`;

  if (!apartmentsCount) {
    return <div/>
  }
 
  return (
    <Block title={title} className={styles.queuesWrapper}>
      {isVillage ? (
        <>
          <VillageTypeHouseFilter
            buildingId={currentBuilding}
            complexId={complexId}
            isVillage={isVillage}
          />
        </>
      ) : (
        <>
          <QueueBuildingsFilter
            className={styles.wrapperQueueBuildingsFilter}
            buildings={buildings}
          />

          <ApartmentQueuesFilter
            buildingId={currentBuilding}
            complexId={complexId}
          />
        </>
      )}
      {isVillage && villageData && (
        <Box mt="20px">
          {Object.keys(villageData).filter(el => villageDataFilter?.length ? villageDataFilter.includes(el): el).map((name: string, idx: number) => (
            <VilligeTypeHouseList
              key={name}
              name={villageData[name].typeName}
              data={villageData[name]}
              statsData={statsGroups?.template}
              complexId={complexId}
            />
          ))}
        </Box>
      )}
      {isVillage && isAllDataEmpty(villageData) && <EmptyData
        title="Для вашего запроса нет результатов"
        description={
          <>
            Попробуйте изменить фильтры или{' '}
            <LinkButton onClick={handleResetVillageFilter || (() => {})}>
              сбросить поиск
            </LinkButton>
          </>
        }
        className={styles.wrapper}
      />}
    </Block>
  );
});
