import { action, makeObservable, observable } from 'mobx';
import { ComplexRaw } from 'services/v1/rc/view/types';

import { RequestApiStore } from 'stores/request/RequestApiStore';
import axios from 'axios';


/**
 * @desc стор хранит данные для отдельного коттеджа
 * */
export class CottageItemStore {
  isVisible = false;

  target: ComplexRaw | null = null;

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      isVisible: observable,
      target: observable.ref,
      toggleVisibility: action.bound,
      setTarget: action.bound,
      fetchCottage: action.bound,
      clearTarget: action.bound,
    });
  }

  clearTarget() {
    this.target = null;
  }

  toggleVisibility(flag: boolean) {
    this.isVisible = flag;
  }

  setTarget = (complex: ComplexRaw[]) => {
    this.target = { ...complex[0] };
  };

  /**
   * @desc получить данные для коттеджа по ID КП и коттеджа
   * */
  fetchCottage = (
    currentComplexId: string | undefined,
    currentBuildingId: string | undefined,
  ) => {
    if (!this.target) {
      this.requestApi.setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_V1}/v1/village/cottages?complexIds=${currentComplexId}&apartmentIds=${currentBuildingId}`,
        )
        .then((res) => {
          if (res?.data) {
            this.setTarget(res.data.data);
            this.requestApi.setLoading(false);
          }
        })
        .catch((err) => {
          console.log('error', err);
          this.requestApi.setError(true);
        });
    }
  };
}

export const cottageItemStore = new CottageItemStore();