import React from 'react';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Text, Avatar, AvatarGroup } from 'components/atoms';
import { appModalRoutesService, Contacts, SalesDepartments } from 'services';
import { useHistory } from 'react-router-dom';
import { CallToActionStatus } from '../CallToActionStatus';
import styles from './styles.module.css';
import { useFeedbackFormInPopup } from '../../../FeedbackForm/hooks/useFeedbackFormInPopup';

type Props = {
  contacts: Contacts[];
  salesDepartment: SalesDepartments;
  type: 'developer' | 'agency';
  isVillage: boolean
};

export const CallToActionContactInfoTestIds = {
  wrapper: 'CallToActionContactInfo_wrapper',
  departmentName: 'CallToActionContactInfo_departmentName',
  showContact: 'CallToActionContactInfo_showContact',
  leaveMessage: 'CallToActionContactInfo_leaveMessage',
  fio: 'CallToActionContactInfo_fio',
};

export const CallToActionContactInfo = ({
  contacts,
  salesDepartment,
  type,
  isVillage, 
}: Props) => {
  const { showFeedbackForm } = useFeedbackFormInPopup();
  const { push } = useHistory();

  const openSalesDepartmentPage = () => {
    push(
      appModalRoutesService.pushNewModalType('salesDepartment', {
        salesDepartmentId: salesDepartment?.id?.toString(),
        salesDepartmentType: type,
        complexId: salesDepartment?.complex_ids?.toString(),
      }),
    );
  };

  const departmentName = salesDepartment.title;
  return (
    <div data-test-id={CallToActionContactInfoTestIds.wrapper}>
      <Text
        tagName="div"
        indent={{
          margin: [0, 0, 5],
        }}
        size="S"
        weight="bold"
      >
        <span
          data-test-id={CallToActionContactInfoTestIds.departmentName}
          dangerouslySetInnerHTML={{
            __html: departmentName || '',
          }}
        />
      </Text>

      <div className={styles.avatarFioAndStatusWrapper}>
        <div className={styles.avatarAndFioWrapper}>
          <AvatarGroup className={styles.avatarWrapper}>
            {contacts.map(({ logo, id }) => (
              <Avatar key={id} src={logo?.logo || ''} alt="realtors" />
            ))}
          </AvatarGroup>
          <Text
            data-test-id={CallToActionContactInfoTestIds.fio}
            size="XS"
            lineHeight="M"
          >
            {contacts.map(({ fio }) => fio).join(', ')}
          </Text>
        </div>

        {contacts.length > 0 && (
          <CallToActionStatus is_working={salesDepartment.is_working} />
        )}
      </div>

      <div className={styles.buttonGroup}>
        <ButtonBase
          onClick={openSalesDepartmentPage}
          data-test-id={CallToActionContactInfoTestIds.showContact}
          color="default"
          fullWidth
        >
          <Text whiteSpace="nowrap" className={styles.showContacts} size="S">
            Показать контакты
          </Text>
        </ButtonBase>

        {!isVillage && <ButtonBase
          onClick={showFeedbackForm}
          data-test-id={CallToActionContactInfoTestIds.leaveMessage}
          color="primary"
          fullWidth
        >
          Оставить заявку
        </ButtonBase>}
      </div>
    </div>
  );
};
