import React, { ReactNode, useCallback } from 'react';
import cc from 'classcat';
import { flow, sortBy, filter, map, flattenDeep } from 'lodash/fp';
import { District } from 'services';
import { TagButton, Text } from 'components/atoms';

import close from 'assets/close.svg';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from '../Icon';

import styles from './styles.module.css';

type SelectInModalProps = {
  data: Array<District>;
  title: ReactNode;
  values: Array<number> | null;
  displayingValues: { title: string, id: number }[];
  onDisplayingValuesChange: React.Dispatch<React.SetStateAction<{ title: string, id: number }[]>>;
  onChange: (newValue: Array<number> | null) => void;
  onClose: () => void;
  clearFilter: () => void;
};

export const SelectInModal = ({
  data,
  title,
  values,
  displayingValues,
  onDisplayingValuesChange,
  onChange,
  onClose,
  clearFilter,
}: SelectInModalProps) => {

  const handleClick = useCallback(
  
    (id: number) => {

      if (!values) {
        values = [];
      }

      const valueToDisplay = data.find((el) => el.id === id);
      if (valueToDisplay) {
        // eslint-disable-next-line no-unused-expressions
        displayingValues.map(el => el.id).includes(valueToDisplay.id)
          ? onDisplayingValuesChange(
              displayingValues.filter((el) => el.id !== valueToDisplay.id),
            )
          : onDisplayingValuesChange([
              ...displayingValues,
              valueToDisplay,
            ]);
      }
      // eslint-disable-next-line no-unused-expressions
      values.includes(id)
        ? onChange(values.filter((el) => el !== id))
        : onChange([...values, id]);
    },
    [values],
  );

  const handleDeleteItem = useCallback(
    (id) => {
      if (values) {
        onChange(values.filter((el) => el !== id));
      }
    },
    [values],
  );

  const regions = flow(
    filter(({ type }) => type === 'округ'),
    sortBy(({ title }: District) => title),
  )(data);

  const districts = flow(
    filter(({ type, rc_count }) => type === 'район' && rc_count > 0),
    sortBy(({ title }: District) => title),
  )(data);

  const regionWithDistricts = map((region: District) => [
    region,
    filter(({ parent_id }: District) => parent_id === region.id)(districts),
  ])(regions);

  const filteredData = flattenDeep(regionWithDistricts) as District[];

  return (
    <section>
      <div className={styles.modalSelectWrapper}>
        <Text className={styles.title} tagName="h2">
          {title}
        </Text>
        <ul className={styles.list}>
          {filteredData.map(({ id, title, parent_id }: District) => (
            <li key={id} className={cc([{ [styles.regionBtn]: !parent_id }])}>
              <TagButton
                type="button"
                onClick={() => handleClick(id)}
                onDelete={() => handleDeleteItem(id)}
                size={parent_id ? 'MD' : 'LG'}
                isSelected={values && values.includes(id)}
              >
                {title}
              </TagButton>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.desktopButtons}>
        <ButtonBase icon={<Icon size={10} src={close} />} onClick={clearFilter}>
          Сбросить
        </ButtonBase>

        <ButtonBase color="primary" onClick={onClose}>
          Показать
        </ButtonBase>
      </div>

      <div className={styles.mobileButtons}>
        <ButtonBase fullWidth color="primary" onClick={onClose}>
          Показать
        </ButtonBase>
        <ButtonBase fullWidth onClick={clearFilter}>
          Сбросить
        </ButtonBase>
      </div>
    </section>
  );
};
