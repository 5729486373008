import React from 'react';
import { buildingFilters } from 'stores/BuildingFilters';
import { Text, InputGroup } from 'components/atoms';
import { ReactiveInput } from 'components/containers/Search/components/ReactiveInput';
import styles from './styles.module.css';

export const SmallFilters = () => (
  <div className={styles.leftWrapper}>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Общая площадь
      </Text>
      <InputGroup>
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="squareMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="squareMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Стоимость
      </Text>
      <InputGroup>
        <ReactiveInput
          store={buildingFilters.filters }
          filterKey="priceMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="priceMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Этаж
      </Text>
      <InputGroup>
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="floorMin"
          placeholder="Не ниже"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="floorMax"
          placeholder="Не выше"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>
  </div>
);
