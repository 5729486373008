import { action, makeObservable, observable, toJS } from 'mobx';
import { Complex } from 'services/v1/rc/view/types';
import { viewService } from 'services';
import { RequestApiStore } from 'stores/request/RequestApiStore';
import { flow, isNil, map, reverse } from 'lodash/fp';
import { ifElse } from '../../utils/fp';
import { fixIsoDateSafari } from '../../utils';

/**
 * @desc стор хранит данные для ЖК и КП
 * */
export class ComplexItemStore {
  isVisible = false;

  target: Complex | null = null;

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      isVisible: observable,
      target: observable,
      toggleVisibility: action.bound,
      setTarget: action.bound,
      fetchComplex: action.bound,
      clearTarget: action.bound,
    });
  }

  clearTarget() {
    this.target = null;
  }

  // @todo: зачем?
  toggleVisibility(flag: boolean) {
    this.isVisible = flag;
  }

  setTarget = (complex: Complex) => {
    this.target = complex;
  };

  /**
   * @desc получить данные для корпусов по ID жк
   * */
  fetchComplex(complexId: number | null) {
    if (complexId) {
      this.requestApi.setLoading(true);
      this.target = null;
      return viewService
        .getView(complexId)
        .then((data) => {
          this.setTarget({
            ...data,
            updated_at: fixIsoDateSafari(data?.updated_at),
            developer: {
              ...data.developer,
              updated_at: fixIsoDateSafari(data?.developer?.updated_at),
              founded_at: fixIsoDateSafari(data?.developer?.founded_at),
            },
          });
          this.requestApi.setLoading(false);
        })
        .catch(() => {
          this.requestApi.setError(true);
        });
    }
    return undefined;
  }

  /**
   * @example [[1,2],[3,4]] ->  [[2,1],[4,3]]
   * */
  reversePolygonCoords = flow<
    any,
    Array<[number, number]>,
    Array<[number, number]>,
    Array<[number, number]>
  >(
    toJS,
    map(reverse),
    ifElse<Array<[number, number]>>(
      isNil,
      () => [],
      (args: Array<[number, number]>) => args,
    ),
  );
}

export const complexItemStore = new ComplexItemStore();
