import React from 'react';
import cc from 'classcat';
import styles from '../../../../components/containers/SalesDepartmentsCard/styles.module.css';
import { BaseLink } from '../../../../components/atoms';
import { dataTestIdToProps } from '../../../../utils/test/dataTestIdToProps';

type Props = {
  complexId?: string;
  buildingId?: string;
  generalPlanId?: string;
  disabled?: boolean;
  isVillage?: boolean;
  className?: string;
};

const OpenLayoutPageButtonTestIDs = {
  button: 'OpenLayoutPageButton_button',
};

export const OpenLayoutPageButton = ({
  complexId,
  buildingId,
  generalPlanId,
  disabled,
  className,
  isVillage = false,
}: Props) => {

  const params = isVillage
    ? {complexId, generalPlanId  }
    : {
      complexId,
      buildingId,
      layoutTabs: 'grid',
    }
  const path = isVillage ? "generalPlan": 'layoutActiveTab' ;
  
  return <BaseLink
    {...dataTestIdToProps(OpenLayoutPageButtonTestIDs.button)}
    color="primary"
    disabled={
      (!buildingId || !complexId || disabled) && !generalPlanId
    }
    path={path}
    fullWidth
    params={params}
    className={cc([styles.gridLayerBtn, className])}
  >
    {isVillage ? 'Смотреть генплан' : 'К квартирам'}
  </BaseLink>
};
