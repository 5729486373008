import React, { useEffect } from 'react';
import { BasePopup } from 'components/atoms';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router';
import { Params } from 'services';
import { cottageItemStore } from 'stores/CottageItemStore/store';
import styles from './styles.module.css';

const CottagePage = () => {
  const history = useHistory();
  const { complexId, buildingId } = useParams<Params>();
  const { isVisible, target, toggleVisibility, fetchCottage, clearTarget } =
    cottageItemStore;

  useEffect(() => {
    if (!target) {
      fetchCottage(complexId, buildingId);
      toggleVisibility(true);
    }
  }, [complexId, buildingId, fetchCottage, toggleVisibility]);


  return (
    <BasePopup
      isOpen={isVisible}
      onClose={() => {
        toggleVisibility(false);
        history.goBack();
        clearTarget();
      }}
      buttonCloseClassName={styles.buttonClosePopup}
      className={styles.complexPageModal}
      size="XL"
    >
      <h3>Id коттеджного поселка {complexId}</h3>
      <h3>Id коттеджа {buildingId}</h3>
      {target?.description}
    </BasePopup>
  );
};

export default observer(CottagePage);
