import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { reaction } from 'mobx';
import { BuildingSearchParameters, getFirstBuildingId, Params } from 'services';
import { buildingFilters } from 'stores';
import { ComplexItemStore } from 'stores/ComplexItemStore/store';
import { ApartmentsStore } from 'stores/Apartment/ApartmentsStore/ApartmentsStore';

type Props = {
  complexItemStore: ComplexItemStore;
  apartmentsStore: ApartmentsStore;
};
export const useApartmentListRequests = ({
  complexItemStore,
  apartmentsStore,
}: Props) => {
  const [initLoading, setInitLoading] = useState(true);
  const { complexId, layoutTabs, buildingId } =
    useParams<Pick<Params, 'complexId' | 'layoutTabs' | 'buildingId'>>();

  const initialRequest = async (
    filter: Partial<BuildingSearchParameters>,
  ): Promise<any> => {
    if (complexId && layoutTabs) {
      const buildingIds = [String(buildingId)] || [
        getFirstBuildingId()(complexItemStore.target?.buildings),
      ];

      apartmentsStore.resetStore();
      apartmentsStore.pageInfo.resetPageInfo();

      return apartmentsStore.loadApartments(
        complexId,
        apartmentsStore.pageInfo.pageInfo,
        {
          ...filter,
          buildingIds,
        },
      );
    }
    return null;
  };

  useEffect(() => {
    const reactionDisposer = reaction(
      () => buildingFilters.filters.filterToApiFormat,
      initialRequest as any,
      {
        delay: 100,
      },
    );

    return () => {
      reactionDisposer();
    };
  }, [buildingFilters, complexId, layoutTabs, buildingId, complexItemStore]);

  useEffect(() => {
    setInitLoading(true);
    void initialRequest(buildingFilters.filters.filterToApiFormat).then(() => {
      setInitLoading(false);
    });
  }, [complexId, buildingId]);

  return {
    initLoading,
    initialRequest,
  };
};
