import React from 'react';
import { observer } from 'mobx-react';
import { BuildingFiltersStore, complexFilterStore } from 'stores';
import { AllFilters } from '../AllFilters';
import styles from './styles.module.css';
import { FilterBottomButtons } from './components/FilterBottomButtons';
import { FilterHeader } from './components/FilterHeader';
import { TOpenType } from 'types/filters';
import { ContextValueVillageFilters } from 'context';

type Props = {
  onClose: () => void;
  openType?: TOpenType;
  store?: BuildingFiltersStore['filters'] | {filters: ContextValueVillageFilters['villageDataFilter']},
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"],
  handleResetFilter?: ContextValueVillageFilters["handleResetVillageFilter"] | any

};

export const AllFiltersPopup = observer(({ onClose, openType, store, handleChangeStore, handleResetFilter }: Props) => {
  return <div className={styles.wrapper}>
    <section className={styles.filtersWrapper}>
      <FilterHeader onClose={onClose} openType={openType} />
      <AllFilters
        openType={openType}
        store={store}
        handleChangeStore={handleChangeStore}
      />
    </section>

    <FilterBottomButtons
      onClose={onClose}
      onCleanFilter={handleResetFilter || complexFilterStore.filters.reset}
    />
  </div>
});
