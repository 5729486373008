import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import cc from "classcat";
import SwiperType from "swiper/types/swiper-class";
import styles from "../../styles.module.css";
import { SliderVideo } from "../SliderVideo";
import { SliderImage } from "../SliderImage";
import { AlbumPhotoToFilter } from "../../../../services";

type Props = {
  photosToShow: AlbumPhotoToFilter[];
  activeIndex: number;
  onSwiper?: (swiper: SwiperType) => void;
  thumbsSwiper: SwiperType | undefined;
  onSlideChange?: (swiper: SwiperType) => void;
};

export const PhotosSwiper = ({
  photosToShow,
  activeIndex,
  onSwiper,
  thumbsSwiper,
  onSlideChange,
}: Props) => {
  // Отфильтровываем только те слайды, которые содержат изображение или видео
  const filteredPhotos = photosToShow.filter(
    ({ url, videoUrl }) => url || videoUrl
  );

  return (
    <Swiper
      className={styles.swiper}
      navigation
      id="albumPageSlider"
      lazy
      thumbs={{ swiper: thumbsSwiper }}
      onSlideChange={onSlideChange}
      onSwiper={onSwiper}
    >
      {filteredPhotos.map(({ id, url, videoUrl }, index) => (
        <SwiperSlide
          key={id}
          className={cc([
            styles.swiperSlide,
            {
              [styles.swiperSlideVideo]: videoUrl,
            },
          ])}
        >
          {url && videoUrl && (
            <SliderVideo
              activePhotoImageURL={filteredPhotos[activeIndex]?.url}
              photoImageURL={url}
              videoURL={videoUrl}
            />
          )}

          {url && !videoUrl && <SliderImage imageUrl={url} />}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
