import React from 'react';
import { observer } from 'mobx-react';
import { SelectSingle } from 'components/atoms';
import { BuildingFilterType } from 'stores/BuildingFilters';
import { infoStore } from 'stores/InfoStore';
import { DictionaryItem, DictionaryKeys } from 'services';
import { ContextValueVillageFilters } from 'context';
import { FilterStore } from 'stores';

type Props = {
  filterKey: any;
  placeholder?: string;
  onlyInternal?: boolean;
  dictKey: DictionaryKeys;
  disabled?: boolean;
  store: FilterStore<any, any> | {filters: ContextValueVillageFilters['villageDataFilter']};
  filterDictionaryItems?: (dictItems: DictionaryItem[]) => DictionaryItem[];
  onChangeVillageTypeHouseContext?: any,
  multiselect?: boolean;
  values?: Record<'day' | 'week'| 'month', string>
};

export const ReactiveSelect = observer(({
  onlyInternal = false,
  placeholder = 'Выбрать',
  filterKey,
  dictKey,
  disabled,
  store,
  filterDictionaryItems,
  onChangeVillageTypeHouseContext,
  multiselect = true,
  values
}: Props) => {
  const filterItem: string[] = store.filters[filterKey];
  let selectItems: DictionaryItem[];

  if (filterDictionaryItems && typeof filterDictionaryItems === 'function') {
    selectItems =
      filterDictionaryItems(infoStore.dictionaries[dictKey]?.items || []) || [];
  } else {
    selectItems = infoStore.dictionaries[dictKey]?.items || [];
  }

  return (
    <SelectSingle
      items={values ?? Object.fromEntries(
        selectItems.map((dictItem) => [dictItem.id, dictItem.value]),
      )}
      value={filterItem || []}
      placeholder={placeholder}
      onChange={(newValue) => {
        if (onChangeVillageTypeHouseContext) {
          onChangeVillageTypeHouseContext?.({ target: {name: filterKey, value: newValue } })
          return
        }

        if (store instanceof FilterStore) {
          store.setField(
            filterKey as keyof BuildingFilterType,
            newValue as string[],
          );
        }
      }}
      multiselect={multiselect}
      disabled={disabled}
      onlyInternal={onlyInternal}
    />
  );
});
