import React, { memo } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { InputGroup } from 'components/atoms';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import styles from '../../AllFilters/AllFilters.module.css';
import { ContextValueVillageFilters } from 'context';
import { ReactiveButtonsGroup } from './ReactiveButtonsGroup';

type Props = {
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] };
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"];
}

export const ThirdColumnFilters = memo(({ store, handleChangeStore}: Props) => (
  <>
     <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Этажность</span>
        <ReactiveButtonsGroup
          buttons={[
            { label: "1 этаж", value: '1', id: 1 },
            { label: "2 этажа", value: '2', id: 2 },
            { label: "3+ этажа", value: '3+', id: 3 }
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageFloor"
        />
  
      </div>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Количество спален</span>
        <ReactiveButtonsGroup
          buttons={[
            { label: "1", value: '1', id: 1 },
            { label: "2", value: '2', id: 2 },
            { label: "3+", value: '3+', id: 3 }
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageBedrooms"
 
        />
      </div>
    </div>
    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Площадь дома</span>
      <InputGroup>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="squareMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="squareMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Площадь участка</span>
      <InputGroup>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="squareLandMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="squareLandMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey='villageMaterial'
          dictKey='village_material'
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey={'villageFacade'}
          dictKey="village_facade"
        />
      </div>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Гараж</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageGarage"
          dictKey="village_garage"
        />
      </div>
       <div className={styles.formItem}>
        <span className={styles.formLabel}>Количество машиномест</span>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="parkingSpaces"
        />
      </div>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Дворовая территория</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageYardTerritory"
          dictKey="village_yard_territory"
        />
      </div>
    </div>
  </>
));
