import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from 'components/styledComponents/Box';

export const CollapseWrap = styled(Box)(
  css({
    paddingTop: 36,
  }),
);

export const CollapseHeader = styled(Box)(
  css({
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  }),
);

export const Title = styled(Box)(
  css({
    fontWeight: 'bold',
    minWidth: '30%',
    fontSize: '26px'
  }),
);

export const Info = styled(Box)(
  css({
    color: '#404042',
    minWidth: '20%',
    fontWeight: 700
  }),
);

export const Counter = styled(Box)(
  css({
    fontWeight: 'bold',
    color: '#4E6AFF',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '30%',
    textAlign: 'right',
    justifyContent: 'end',
  }),
);

export const TableRow = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '134px',
    alignItems: 'center',
  }),
);

export const TableCol = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const TItemImg = styled(Box)(
  css({
    width: '134px',
  }),
);

export const TItemSecond = styled(Box)(
  css({
    fontWeight: 'bold',
  }),
);

export const TItemFirst = styled(Box)(
  css({
    pb: '5px',
    fontSize: '12px',
    lineHeight: '16px',
  }),
);

export const Price = styled(Box)(
  css({
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
  }),
);
export const PriceM = styled(Box)(
  css({
    fontSize: '16px',
    lineHeight: '24px',
  }),
);

export const BorderRow = styled(Box)(
  css({
    height: '1px',
    bg: '#DFDFEB',
    width: `calc(100% - 175px)`,
    display: 'flex',
    ml: '175px',
  }),
);
