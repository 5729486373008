import React from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';
import {  BuildingFilterType } from 'stores/BuildingFilters';
import { ContextValueVillageFilters } from 'context';
import { FilterStore } from 'stores';
import { ButtonGroup } from 'pages/LayoutPage/components/ButtonGroup';

type Props = {
  buttons: {label: string, value: string, id: number}[]
  filterKey: any;
  store: FilterStore<any, any> | {filters: ContextValueVillageFilters['villageDataFilter']};
  onChangeVillageTypeHouseContext?: ((e: {
    target: any;
  }) => void) | undefined;
};

export const ReactiveButtonsGroup = observer(({
  buttons,
  filterKey,
  store,
  onChangeVillageTypeHouseContext
}: Props) => {
  const filterItem: number | undefined = store.filters[filterKey];

  const inputValue = String(
    isNil(filterItem) || filterItem === 0 ? '' : filterItem,
  );
  
  const onChange = (buttonId: any) => {
    const value = buttons.find(el => el.id === buttonId)?.value

    if (onChangeVillageTypeHouseContext) {
      onChangeVillageTypeHouseContext({target: {name: filterKey , value}})
      return
    }

    if ( store instanceof FilterStore) {
      store.setField(filterKey as keyof BuildingFilterType, value);
    }
  };

  return (
    <ButtonGroup
      size="medium"        
      variant="contained"
      buttons={buttons}
      onClick={onChange}
      value={inputValue}
      
    />
  );
});
