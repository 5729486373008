import { useState } from "react";

import cc from 'classcat';
import classes from './ButtonGroup.module.css';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase/index';

export type ButtonGroupProps = {
  buttons: Array<Omit<ButtonBaseProps, 'size' | 'variant'> & { label: string, value: string }>;
  size?: ButtonBaseProps['size'];
  variant?: ButtonBaseProps['variant'];
  className?: string;
  onClick: (id: number | null) => void
  value?: string;
};

export const ButtonGroup = ({ buttons, className, size = "medium", variant = "contained", onClick, value }: ButtonGroupProps) => {
  
  const selectedIndexByValue = value ? buttons.find(el => el.value === value)?.id : null;

  const [selectedIndex, setSelectedIndex] = useState<number | null>(selectedIndexByValue);

  const handleButtonClick = (id: number) => {
    if (id === selectedIndex) {
      setSelectedIndex(null);
       onClick(null)
    } else {
      setSelectedIndex(id); 
      onClick(id)
    }
  };

  return (
    <div className={cc([classes.buttonGroup, className])}>
      {buttons.map((button, index) => (
        <ButtonBase
          key={index}
          size={size}
          variant={variant}
          onClick={() => handleButtonClick(button.id)} 
          isSelected={button.id === selectedIndex}
          {...button}
        >
          {button.label}
        </ButtonBase>
      ))}
    </div>
  );
};
