import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import { LogoWithContent, BaseLink, Icon } from 'components/atoms';
import { Complex, getFirstBuildingId } from 'services';

import iconRight from 'assets/rightIcon.svg';
import { StatsTotal } from '../StatsTotal';

import styles from './styles.module.css';

type Props = {
  complex: Complex;
};


export const ComplexInfoHeader = ({ complex }: Props) => {
  const isVillage = complex?.type === 'village'; // КП

  const baseParams = {
    complexId: complex?.id?.toString(),
  };

  const villageParams = {
    ...baseParams,
    generalPlanId: complex?.sectionLogo?.id,
  };

  const rcParams = {
    ...baseParams,
    layoutTabs: 'grid',
    buildingId: getFirstBuildingId(true)(complex?.buildings)?.toString(),
  };

  const params = isVillage ? villageParams : rcParams;
  const path = isVillage ? "generalPlan" : "layoutActiveTab";

  return <div className={styles.wrapper}>
    <div className={styles.left_content}>
      <LogoWithContent src={complex.logo?.url || ''} alt="logo">
        <div className={styles.complexTitle}>
          <LinesEllipsis
            text={complex.title?.trim()}
            maxLine="2"
            ellipsis="..."
            trimRight
          />
        </div>
      </LogoWithContent>
    </div>

    <div className={styles.right_content}>
      <StatsTotal total={complex.stats?.total} />
      <BaseLink
        params={params}
        color="primary"
        variant='text'
        path={path}
        icon={<Icon src={iconRight} />}
        iconRight
        className={styles.toFlatsLink}
      >
        {isVillage ? 'Генплан' : 'Шахматка и план'}
      </BaseLink>
    </div>
  </div>
};