import React, { useMemo } from 'react';
import cc from 'classcat';
import { Complex } from 'services/v1/rc/view/types';
import { Text } from 'components/atoms/Text';
import sample from 'assets/sampleBenefitIcon.svg';
import { infoStore } from 'stores/InfoStore';
import { Icon } from 'components/atoms/Icon';
import { observer } from 'mobx-react';
import { Block } from '../../components/Block';

import styles from './styles.module.css';

type Props = {
  innerRef?: React.RefObject<HTMLDivElement>;
  complexCatalogs?: Pick<Complex, 'catalogs'>['catalogs'];
  className?: string;
  isVillage?: boolean;
};

type BenefitItem = { title: string; icon: string; disable: boolean };

/**
 * @todo: подключить иконки когда они будут отрисованы
 * @todo: избавится от компонента FlexBox
 * */
export const BenefitsRender = ({
  complexCatalogs,
  innerRef,
  className,
  isVillage,
}: Props) => {
  const { requestApi } = infoStore;

  const keyAdvantages = isVillage ? 'village_advantages' : "residential_complex_advantages"
  const benefits: BenefitItem[] = useMemo(
    () =>
      infoStore
        .getInfoBy(keyAdvantages)
        ?.items.map((item) => ({
          title: item.value,
          icon: sample,
          disable: !complexCatalogs?.[keyAdvantages]?.includes(
            item.id,
          ),
        })) || [],
    [requestApi.isLoading, keyAdvantages, complexCatalogs],
  );

  return (
    <Block
      title="Преимущества"
      className={cc([styles.benefitsWrapper, className])}
    >
      <div className={styles.benefitsList} ref={innerRef}>
        {benefits &&
          benefits.map((benefit) => (
            <div key={benefit.title} className={styles.benefit}>
              <div
                className={cc([
                  styles.icon,
                  { [styles.iconDisabled]: benefit.disable },
                ])}
              >
                <Icon
                  size={20}
                  src={benefit.icon}
                  className={styles.iconTag}
                  alt="icon"
                />
              </div>

              <div className={styles.title}>
                <Text size="S" lineHeight="L">
                  {benefit.title}
                </Text>
                {benefit.disable && (
                  <Text className={styles.disabled} size="XXS" lineHeight="XS">
                    Отсутствует
                  </Text>
                )}
              </div>
            </div>
          ))}
      </div>
    </Block>
  );
};

export const Benefits = observer(BenefitsRender);
