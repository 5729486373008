import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { floorPlanStore } from '../../../../stores/FloorPlanStore';
import { ApartmentDto, appRoutesService } from '../../../../../../services';

type Props = {
  complexId: string | undefined;
  buildingId: string | undefined;
  layoutTabs: string | undefined;
  apartments: ApartmentDto[];
};

export const useOpenAnApartmentByClickOnAPolygon = ({
  complexId,
  buildingId,
  layoutTabs,
  apartments,
}: Props) => {
  const { push } = useHistory();

  /**
   * @desc открытие квартиры по клику на планировку,
   * меняет URL И делает запись в floorPlanStore
   * */
  const onPolygonClick = useCallback(
    (index: number) => () => {
      const apartment = apartments[index];
      if (!apartment) {
        return;
      }
      floorPlanStore.setActiveApartment(apartment);

      push(
        appRoutesService.replaceRoute('layoutActiveApartment', {
          complexId,
          buildingId,
          layoutTabs,
          apartmentId: apartment?.id?.toString(),
          apartmentNumber: apartment?.number?.toString(),
        })
      );
    },
    [apartments, complexId, buildingId, layoutTabs]
  );

  return {
    onPolygonClick,
  };
};