import React from 'react';
import { complexFilterStore } from 'stores';
import { observer } from 'mobx-react';
import styles from './styles.module.css';
import { ButtonBase } from '../../../../../pages/LayoutPage/components/ButtonBase';
import { Icon } from '../../../../atoms/Icon';
import close from '../../../../../assets/close.svg';
import { TabButton, TabList } from '../../../Tabs';
import { TOpenType } from 'types/filters';

const tabs = [
  {
    label: 'Жилые комплексы',
  },
  {
    label: 'Коттеджные поселки',
  },
];

type Props = {
  onClose: () => void;
  openType?: TOpenType;
};

export const FilterHeader = observer(({ onClose, openType = 'main' }: Props) => {
  const { currentFilterTab, setFilterTab } = complexFilterStore.filters;

  return (
    <div className={styles.filtersHeader}>
      <h2 className={styles.title}>Фильтры</h2>

      {openType === 'main' &&  <TabList currentActiveTab={currentFilterTab}>
        {tabs.map(({ label }, idx) => (
          <TabButton
            isActive={idx === currentFilterTab}
            onClick={() =>  setFilterTab(idx)}
            key={label}
            label={label}
          />
        ))}
      </TabList>}

      <ButtonBase
        onClick={onClose}
        icon={<Icon size={10} alt="close" src={close} />}
      >
        Закрыть
      </ButtonBase>
    </div>
  );
});
