import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { BasePopup } from 'components/atoms/Popup/BasePopup';

import iconLeft from 'assets/leftIcon.svg';

import { CardTabs } from 'pages/ComplexPage/components/CardTabs';
import { FlexBox, ModalRoute, FloorPlanOverlay, Text, BaseMapContainer } from 'components/atoms';
import { NearbyObjects } from 'pages/ComplexPage/components/NearbyObjects';
import { appRoutesService, getFirstBuildingId, Params } from 'services';
import { feedbackStore } from 'stores/FeedbackStore/FeedbackStore';
import CottagePage from '../CottagePage/CottagePage';

import { PhotoAlbumContainer } from 'components/containers/Albums/PhotoAlbumContainer';
import { guardedModalRoute } from 'hocs';
import { TabButton, TabList } from 'components/containers/Tabs';
import {  TypeHouseProvider } from 'context';
import { ComplexPresentationCarousel } from './containers/ComplexPresentationCarousel';
import { ComplexInfo } from './containers/ComplexInfo';
import { Benefits } from './containers/Benefits';
import { ComplexInfoDescription } from './containers/ComplexInfo/components/ComplexInfoDescription';
import { CatalogsTable } from './containers/ComplexInfo/components/CatalogsTable';

import { complexItemStore } from '../../stores/ComplexItemStore/store';
import { DealDocuments } from './components/DealDocuments';
import { SalesDepartmentsCard } from '../../components/containers/SalesDepartmentsCard';
import { BaseLink } from '../../components/atoms';

import styles from './styles.module.css';
import { PromotionPage } from '../PromotionPage';
import { PromotionsSection } from './containers/PromotionsSection';
import { ConstructionProgressContainers } from '../../components/containers/Albums/ConstructionProgressContainers';
import { dataTestIdToProps } from '../../utils/test/dataTestIdToProps';
import { AuthPage } from '../AuthPage';
import { SalesDepartmentsPage } from '../SalesDepartmentsPage';
import { authRoutesStore } from '../../stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { AuthForGetAccessToSalesDepartments } from './pages/AuthForGetAccessToSalesDepartments';
import { buildingFilters, complexFilterStore } from '../../stores';
import * as S from './ComplexPage.styled';
import { getComplexTabs, getVillageTabs } from './utils';
import { ComplexPageTestIDs, mapTabs } from './consts';
import { Block } from './components/Block';
import { QueuesBlock } from './containers/QueuesBlock';

 /**
 * @desc Единная страница для ЖК и КП
 * */

export const ComplexPageRender = () => {
  const history = useHistory();
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  const docsRef = useRef<HTMLDivElement>(null);
  const photosRef = useRef<HTMLDivElement>(null);
  const benefitsRef = useRef<HTMLDivElement>(null);
  const constructionRef = useRef<HTMLDivElement>(null);
  const nearRef = useRef<HTMLDivElement>(null);

  const [currentMapTab, setMapTab] = useState(0);
    const [forwardedContentRef, setForwardedContentRef] = useState<
    HTMLDivElement | undefined
  >(undefined);

  const {
    isVisible,
    target: complexData,
    toggleVisibility,
    fetchComplex,
    clearTarget,
  } = complexItemStore;

  const isVillage = complexData?.type === 'village'; // КП

  const targetBadges: number[] =
    (complexData?.catalogs?.residential_complex_badges &&
      [...complexData?.catalogs?.residential_complex_badges].sort(
        (a, b) => a - b,
      )) ||
    [];

  const tabs = isVillage
    ? getVillageTabs(complexData?.albums ?? [])
    : getComplexTabs(complexData?.albums ?? []);
  
  const tabItemList = Object.keys(tabs).reduce((acc, current) => {
    const tab = tabs[current as keyof typeof tabs]; 
    if (tab?.show) {
      acc[current as keyof typeof tabs] = tab.title; 
    }
    return acc;
  }, {} as Record<keyof typeof tabs, string>);

  const formattedPolygon: Array<[number, number]> =
    complexItemStore.reversePolygonCoords(complexData?.polygon);

  useEffect(() => {
    if (!complexData || complexData?.id !== Number(complexId)) {
      fetchComplex(Number(complexId));
      toggleVisibility(true);
      feedbackStore.setInitialValues({
        type: 'complex',
        recordId: complexId,
      });
    }
  }, [complexId, fetchComplex, toggleVisibility, complexData]);

  useEffect(() => {
    /**
     * @desc сохранение состояния фильтра с главной страницы для фильтра в шахматке
     * */
    buildingFilters.filters.setFields(complexFilterStore.filters.filters);

    const url = appRoutesService.replaceRoute('view', {
      complexId,
    });
    authRoutesStore.setParentRoute(url);
  }, [complexId]);

  useEffect(() => {
    return () => {
      authRoutesStore.setParentRoute('');
      authRoutesStore.setSuccessAuthRoute('');
    };
  }, []);
  if (complexData === null) {
    return null;
  }

  return (
    <BasePopup
      isOpen={isVisible}
      onClose={() => {
        toggleVisibility(false);
        history.push('/');
        clearTarget();
      }}
      buttonCloseClassName={styles.buttonClosePopup}
      className={styles.complexPageModal}
      size="XL"
      setForwardedContentRef={setForwardedContentRef}
    >
      <div
        {...dataTestIdToProps(ComplexPageTestIDs.wrapper)}
        className={styles.root}
      >
        {complexData && (
          <ComplexPresentationCarousel
            complex={complexData}
            badges={targetBadges}
          />
        )}

        <div className={styles.toMainPageLink}>
          <BaseLink
            path="main"
            icon={<img src={iconLeft} alt="go to main page" />}
            className={styles.toFlatsLink}
          >
            Назад на главную
          </BaseLink>
        </div>

        <CardTabs
          items={tabItemList}
          refsMap={{
            near: nearRef,
            documents: docsRef,
            albums: photosRef,
            benefits: benefitsRef,
            constructionAlbums: constructionRef,
          }}
          modalContentRef={forwardedContentRef}
        />

        <FlexBox
          className={styles.contentRoot}
          flexDirection="column"
          alignItems="normal"
        >
          <section className={styles.mainInfoWrapper}>
            <div className={styles.complexPage_wrapper_complexInfo}>
              <ComplexInfo complex={complexData} polygon={formattedPolygon} />
            </div>

            <div className={styles.descriptionAndCatalog_wrapper}>
              {complexData?.description && (
                <div
                  className={styles.descriptionAndCatalog_description_wrapper}
                >
                  <ComplexInfoDescription
                    content={complexData?.description || ''}
                  />
                </div>
              )}
              <CatalogsTable
                catalogs={complexData.catalogs}
                excludeInfoKeys={[
                  'residential_complex_advantages',
                  'residential_complex_badges',
                  'village_advantages'
                ]}
              />
            </div>

            {complexData.developer && (
              <div className={styles.salesDepartmentsCard_wrapper}>
                <SalesDepartmentsCard
                  complexId={complexData.id}
                  complexUpdatedAt={complexData.updated_at}
                  generalPlanId={complexData.sectionLogo?.id}
                  developer={complexData.developer}
                  salesDepartments={complexData.salesDepartments}
                  buildingId={getFirstBuildingId(true)(complexData.buildings)}
                  isVillage={isVillage}
                />
              </div>
            )}
          </section>

          {Array.isArray(complexData.stocks) && (
            <PromotionsSection list={complexData.stocks} />
          )}

          <Block>
          <FlexBox justifyContent="space-between" alignItems="center">
             <Text weight='700' size='3XL'>{`Расположение ${isVillage ? '' : 'и план'}`}</Text>
              {!isVillage && <S.TabsMenuWrapper>
                <TabList currentActiveTab={currentMapTab}>
                  {mapTabs.map(({ label }, idx) => (
                    <TabButton
                      isActive={idx === currentMapTab}
                      onClick={() => setMapTab(idx)}
                      key={label}
                      label={label}
                    />
                  ))}
                  </TabList>
              </S.TabsMenuWrapper>}
          </FlexBox>
          
            {currentMapTab === 0 && complexData.buildings && (
              <NearbyObjects
                innerRef={nearRef}
                description="Поблизости"
                buildings={complexData.buildings}
                polygon={formattedPolygon}
              />
            )}

            {currentMapTab === 1 && complexData?.sectionLogo && (
              <BaseMapContainer
                crs={L.CRS.Simple}
                scrollWheelZoom
                minZoom={-0.7}
                maxZoom={3}
                maxBoundsViscosity={1}
                className={styles.map}
              >
                <FloorPlanOverlay imageUrl={complexData.logo.logo} fitBoundsOfPlan/>
              </BaseMapContainer>
          )}
          </Block>

          <TypeHouseProvider>
            <QueuesBlock
              apartmentsCount={complexData?.stats?.total?.count || 0}
              buildings={complexData.buildings}
              isVillage={isVillage}
              statsGroups={complexData.statsGroups}
            />
          </TypeHouseProvider>

          <Benefits
            complexCatalogs={complexData.catalogs}
            innerRef={benefitsRef}
            isVillage={isVillage}
          />

          <PhotoAlbumContainer
            albums={complexData.albums || []}
            complexId={complexData.id}
            innerRef={photosRef}
          />

          <ConstructionProgressContainers
            albums={complexData.albums || []}
            complexId={complexData.id}
            innerRef={constructionRef}
          />

          <DealDocuments
            documents={complexData.documents}
            innerRef={docsRef}
            catalogs={complexData.catalogs}
            infoKeys={[
              'residential_complex_contract_type',
              'residential_complex_payment_type',
            ]}
          />
        </FlexBox>
      </div>
      <Switch>
        <Route path={appRoutesService.getRoute('stock')}>
          <PromotionPage />
        </Route>
         <Route
          path={appRoutesService.getRoute('cottage')}
          component={CottagePage}
        />
      </Switch>

      <AuthPage
        components={{
          authPhoneCodePage: () => <AuthForGetAccessToSalesDepartments />,
          authEmailCodePage: () => <AuthForGetAccessToSalesDepartments />,
        }}
        parentRoute={appRoutesService.getRoute('view')}
      />
      <ModalRoute
        component={guardedModalRoute(
          SalesDepartmentsPage,
          authRoutesStore.getRouteWithParent('authPhoneCodePage'),
        )}
        path="salesDepartment"
      />
    </BasePopup>
  );
};

export const ComplexPage = observer(ComplexPageRender);
