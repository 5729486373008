import React, { ReactElement, CSSProperties, Key } from 'react';
import cc from 'classcat';

import closeBtn from 'assets/close.svg';

import styles from './styles.module.css';

type ButtonType = 'button' | 'submit' | 'reset';

type Props = {
  onClick?: () => void;
  onDelete?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  children: ReactElement | string;
  className?: string;
  type?: ButtonType;
  style?: CSSProperties;
  size?: 'LG' | 'MD';
  isSelected?: boolean | null;
  disabled?: boolean;
  key: Key
};

export const TagButton = ({
  onClick,
  type = 'button',
  className = '',
  onDelete,
  size = 'MD',
  children,
  style = {},
  isSelected,
  disabled,
  key,
}: Props) => (
  /* eslint-disable react/button-has-type */
  <button
    key={key}
    type={type}
    onClick={onClick}
    className={cc([
      styles.filterButton,
      {
        [styles.largeFilterBtn]: size === 'LG',
        [styles.selectedButton]: isSelected,
        [styles.disabledButton]: disabled,
      },
      className,
    ])}
    style={style}
  >
    {isSelected && (
      <span  className={styles.closeButton}>
        <img
          src={closeBtn}
          className={styles.closeButtonIcon}
          alt="close modal"
          onClick={(e) => onDelete?.(e)}
        />
      </span>
    )}
    <span>{children}</span>
  </button>
);
