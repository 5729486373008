import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import React, { memo } from 'react';
import options from 'assets/optionsColorIcon.svg';
import closeColorIcon from 'assets/closeColorIcon.svg';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import deepEqual from 'fast-deep-equal';
import { Params } from 'services';
import { TabsSelect } from 'components/containers/TabsSelect';
import { Icon, BaseLink } from 'components/atoms';
import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';

import styles from './styles.module.css';

type Props = {
  /**
   * @desc метод для открытия фильтра в шахматке
   * */
  showAllFiltersPopup: () => void;
} & Pick<ReturnType<typeof useLayoutTabsState>, 'currentActiveTab'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'tabList'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'changeTab'>;

export const LayoutPageHeaderMobileRender = ({
  showAllFiltersPopup,
  tabList,
  currentActiveTab,
  changeTab,
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  return (
    <>
      <header className={styles.layoutPageHeaderMobileHeader}>
        <ButtonBase
          onClick={showAllFiltersPopup}
          color="transparent"
          icon={<Icon src={options} size={24} alt="открыть фильтр" />}
        />

        <TabsSelect
          tabList={tabList}
          changeTab={changeTab}
          currentActiveTab={currentActiveTab}
        />

        <BaseLink
          path="view"
          params={{
            complexId,
          }}
          color="transparent"
          icon={<Icon size={24} src={closeColorIcon} alt="назад к ЖК" />}
        />
      </header>
    </>
  );
};
export const LayoutPageHeaderMobile = memo(
  observer(LayoutPageHeaderMobileRender),
  deepEqual,
);
