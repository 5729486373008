import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import SwiperType from 'swiper/types/swiper-class';
import styles from './styles.module.css';
import { Box } from 'components/styledComponents/Box';
import { Text } from 'components/atoms';
import { SwiperPlan } from './SwiperPlan';
import { pluralize } from 'utils/pluralize';
import { ComplexRaw } from 'services';


type Props = {
  boxes: ComplexRaw[];
  activeIndex: number;
  onSwiper?: (swiper: SwiperType) => void;
  thumbsSwiper: SwiperType | undefined;
  onSlideChange?: (swiper: SwiperType) => void;
};

export const BoxSwiper = ({
  boxes,
  onSwiper,
  thumbsSwiper,
  onSlideChange,
}: Props) => {

  if (!boxes) return null
  return (
    <Box position="relative">
    <Swiper
      className={styles.swiper}
      navigation
      spaceBetween={16}
      slidesPerView={1.5}
      id='boxSwiper'
      thumbs={{ swiper: thumbsSwiper }}
      onSlideChange={onSlideChange}
        onSwiper={onSwiper}
        breakpoints={{
              540: {
                slidesPerView: 2.3,
              },
              769: {
                slidesPerView: 2.8,
              },
                1025: {
                spaceBetween: 12,
                slidesPerView: 3,
              },
           }}
        watchOverflow
        
    >
      {boxes.map((el) => (
        <SwiperSlide
          className={styles.swiperSlide}
        >
          <Box className={styles.previewInfo}>
            <SwiperPlan box={el} />
            <Box display={'flex'} flexDirection={'column'} style={{gap: '8px'}}>
              <Box>
                <Text weight='700' size='L'>{el.title ?? '1'}</Text>
              </Box>
              <Box className={styles.price}>
                <Text size='M'>{`${Number(el.price_m2).toLocaleString()} ₽/м²`}</Text>
                <Text weight='700' size='L'>{`${Number(el.price).toLocaleString()} ₽`}</Text>
              </Box>
              <Box display={'flex'} flexDirection={'row'}  className='pompon'>
                {el.number_floors && <Box>
                  {`${pluralize(el.number_floors,[ 'этаж', 'этажа', 'этажей'])}`}
                  </Box>
                }
                {el.number_bedrooms && <Box>
                  {`${pluralize(el.number_bedrooms, ['спальня', 'спальни', 'спален'])}`}
                  </Box>
                }
                <Box>{`${pluralize(el.apartments_count, ['коттедж', 'коттеджа', 'коттеджей'])}`} </Box>
                {el.square && <Box>
                  {`${el.square.split('.')[0]} м²`}
                  </Box>
                }
              </Box>
            </Box>
          </Box>
        </SwiperSlide>
      ))}
      </Swiper>
      </Box>)
}