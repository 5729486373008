import { VillageDataProps } from "context";
import { ComplexRaw } from "services";

  export const numberFormatter = (num: number, minVal: number, letter: string) =>
    Math.sign(num) * ((Math.floor(num) / minVal).toFixed(1) as never) + letter;

  export const formatPrice = (num: number) => {
    if (!num) {
      return 0;
    }

    if (num >= 1000000) {
      return numberFormatter(num, 1.0e6, ' млн');
    }

    if (num >= 10000) {
      return numberFormatter(num, 1.0e3, ' тыс');
    }

    return num.toLocaleString();
  };

  export const filteredMin = (data: VillageDataProps[string] , val: keyof ComplexRaw) =>
    data.data?.reduce((prev: ComplexRaw, current: ComplexRaw) => {
      return (prev?.[val] ?? current) < (current?.[val] ?? prev) ? prev : current;
    })?.[val];


  export const filteredMax = (data: VillageDataProps[string], val: keyof ComplexRaw) =>
    data?.data?.reduce((prev: ComplexRaw, current: ComplexRaw) => {
      return (prev?.[val] ?? current) > (current?.[val] ?? prev) ? prev : current;
    }
    )[val];
