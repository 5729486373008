import React from 'react';
import { observer } from 'mobx-react';

import { BuildingFiltersStore, complexFilterStore } from 'stores';
import styles from './AllFilters.module.css';

import { TOpenType } from 'types/filters';
import { ComplexTab } from './ComplexTab';
import { VillageTab } from './VillageTab';
import { ContextValueVillageFilters } from 'context';

type Props = {
  openType?: TOpenType,
  store?: BuildingFiltersStore['filters'] | {filters: ContextValueVillageFilters['villageDataFilter']},
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"], 
}

export const AllFilters = observer(({ openType = 'main', store, handleChangeStore }: Props) => {

  const renderContent = () => {
    if (openType === 'complex') {
      return <ComplexTab isShowFromComplex store={store as BuildingFiltersStore['filters']}/>;
    }

    if (openType === 'village') {
      return (
        <VillageTab
          isShowFromVillage
          store={store}
          handleChangeStore={handleChangeStore}
        />
      );
    }

    if (openType === 'main') {
      return complexFilterStore.filters.filterTab === 0 ? (
        <ComplexTab />
      ) : (
        <VillageTab
          store={store}
          handleChangeStore={handleChangeStore}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.formWrapper}>
      {renderContent()}
    </div>
  );
});
