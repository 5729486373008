import { useEffect, useState } from "react";

export const useIframeMode = () => {
  const [isIframeMode, setIsIframeMode] = useState(false); 

  useEffect(() => {
    if (window.self !== window.top) {
      // условие сработает, если данный компонент отрисовывается в iframe на другом сайте
      setIsIframeMode(true);
    } else {
      setIsIframeMode(false);
    }
  }, []);
  
  return isIframeMode
}