import React, { CSSProperties, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';
import {  BuildingFilterType } from 'stores/BuildingFilters';
import { Input } from 'components/atoms/Form/Input';
import { ContextValueVillageFilters } from 'context';
import { FilterStore } from 'stores';

type Props = {
  filterKey: any;
  store: FilterStore<any, any> | {filters: ContextValueVillageFilters['villageDataFilter']};
  placeholder?: string;
  customIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  customStyles?: CSSProperties;
  onChangeVillageTypeHouseContext?: ((e: {
    target: any;
  }) => void) | undefined;
};

export const ReactiveInputRender = ({
  customIcon,
  customStyles,
  placeholder = 'Выбрать',
  filterKey,
  store,
  disabled,
  className,
  onChangeVillageTypeHouseContext
}: Props) => {
  const filterItem: number | undefined = store.filters[filterKey];

  const inputValue = String(
    isNil(filterItem) || filterItem === 0 ? '' : filterItem,
  );

  const onChange = (e: any) => {
    const { value } = e.target;

    if (onChangeVillageTypeHouseContext) {
      onChangeVillageTypeHouseContext(e)
      return
    }

    if (!Number.isNaN(+value) && store instanceof FilterStore) {
      store.setField(filterKey as keyof BuildingFilterType, +value);
    }
  };

  return (
    <Input
      onChange={onChange}
      name={filterKey}
      maxLength={9}
      value={inputValue}
      placeholder={placeholder}
      customIcon={customIcon}
      className={className}
      customStyles={customStyles}
      disabled={disabled}
    />
  );
};

export const ReactiveInput = observer(ReactiveInputRender);
