import React, { memo } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';
import { InputGroup } from 'components/atoms';
import { PopupDistrictsChoicer } from 'components/containers/PopupDistrictsChoicer';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import styles from '../../AllFilters/AllFilters.module.css';
import { ContextValueVillageFilters } from 'context';

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?:ContextValueVillageFilters["handleChangeVillageFilter"]
}

export const FirstColumnFilter = memo(({isShowFromVillage, store, handleChangeStore}: Props) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;  

  return (
    <>
       <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Район</span>
        <PopupDistrictsChoicer
          data={districts || []}
          filterKey="districtIds"
          store={complexFilterStore.filters}
          disabled={isLoading || isShowFromVillage}
          placeholder="Любой"
        />
      </div>
      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Стоимость</span>
        <InputGroup>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="priceMin"
            placeholder="Общая, от"
            className={styles.complexInputLeft}
            onChangeVillageTypeHouseContext={handleChangeStore}
          />
          <ReactiveInput
            store={ store || complexFilterStore.filters}
            filterKey="priceMax"
            placeholder="Общая, до"
            className={styles.complexInputRight}
            onChangeVillageTypeHouseContext={handleChangeStore}
          />
        </InputGroup>
      </div>
      <div className={styles.formSubColumnWrapper}>
        <div className={styles.formItem}>
          <span className={styles.formLabel}>Тип участка</span>
          <ReactiveSelect
            store={complexFilterStore.filters}
            filterKey="villageTypeLand"
            dictKey="village_type_land"
            placeholder="Не важно"
            disabled={isShowFromVillage}
          />
        </div>

        <div className={styles.formItem}>
          <span className={styles.formLabel}>ВРИ</span>
          <ReactiveSelect
            store={complexFilterStore.filters}
            filterKey="villageVRI"
            dictKey="village_vri"
            disabled={isShowFromVillage}
          />
        </div>
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип дома</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          filterKey="villageTypeHouse"
          placeholder="Любой"
          dictKey="village_type_house"
          onChangeVillageTypeHouseContext={handleChangeStore}
        />
      </div>
      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Расстояние до города</span>
        <InputGroup>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="distanceToCityMin"
            placeholder="От"
            className={styles.complexInputLeft}
            disabled={isShowFromVillage}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="distanceToCityMax"
            placeholder="До"
            className={styles.complexInputRight}
            disabled={isShowFromVillage}
          />
        </InputGroup>
      </div>
        <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Дата обновления КП</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          filterKey="publishPeriod"
          placeholder="Любой"
          onChangeVillageTypeHouseContext={handleChangeStore}
          values={{ day: '24 часа', week: 'За неделю', month: 'За месяц' }}
          multiselect={false}
        />
      </div>
      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Агентское вознаграждение, %</span>
        <InputGroup>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="agentCommissionPercentMin"
            placeholder="От"
            className={styles.complexInputLeft}
            disabled={isShowFromVillage}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="agentCommissionPercentMax"
            placeholder="До"
            className={styles.complexInputRight}
            disabled={isShowFromVillage}
          />
        </InputGroup>
      </div>
    </>
  );
});
