import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Buildings from 'stores/Buildings';
import { BaseMapContainer } from 'components/atoms';
import LeafletStore from 'stores/LeafletStore';
import { useId } from 'react-id-generator';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { SetMapInstanceToStore } from './SetMapInstanceToStore';
import { infoStore } from '../../../../stores';
import { useUpdateMapCenter, useInitMapCenter } from './useMapCenter';
import { MarketTooltip } from './components/MarketTooltip';
import L from 'leaflet';

const MainPageMapRender = () => {
  useInitMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  useUpdateMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  const { complexes } = Buildings;

  const {
    center,
    instance: mapInstance,
    setSuppressMoveHandler,
    displayedPointsType,
  } = LeafletStore;

  const idList: string[] = useId(complexes.data?.pins.length, 'complexes_pins');

  useEffect(() => {
    // если необходимо отобразить объекты девелопера - делаем перерасчет центра карты здесь
    if (
      displayedPointsType === 'developer' &&
      complexes?.data?.pins &&
      mapInstance
    ) {
      // расчет границ на основе всех точек девелопера
      const bounds = L.latLngBounds(
        complexes.data.pins.map(pin => ([parseFloat(pin.latitude), parseFloat(pin.longitude)]))
      );
      // устанавливка центра и масштаба карты так, чтобы все объекты были видны
      mapInstance.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [complexes.data?.pins, mapInstance, displayedPointsType, setSuppressMoveHandler]);

  useEffect(() => LeafletStore.clear, []);

  return (
    <BaseMapContainer
      scrollWheelZoom
      center={center}
      zoom={12}
      mapInstanceForZoom={mapInstance}
    >
      <YandexMapLayer />
      {complexes.data?.pins?.map((pin, index) => (
        <MarketTooltip key={idList[index]} pin={pin} />
      ))}
      <SetMapInstanceToStore leafletStore={LeafletStore} />
    </BaseMapContainer>
  );
};

export const MainPageMap = observer(MainPageMapRender);
