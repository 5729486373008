import React from 'react';

import { FirstColumnFilter } from '../Search/components/FirstColumnFilters';
import { SecondColumnFilter } from '../Search/components/SecondColumnFilters';
import { ThirdColumnFilters } from '../Search/components/ThirdColumnFilters';

import styles from './AllFilters.module.css';
import { BuildingFiltersStore } from 'stores';

type Props = {
  isShowFromComplex?: boolean;
  store?: BuildingFiltersStore['filters'];
}

export const ComplexTab = ({isShowFromComplex = false, store}: Props) => (
  <>
    <div className={styles.formColumn}>
      <FirstColumnFilter isShowFromComplex={isShowFromComplex} store={store} />
    </div>

    <div className={styles.formColumn}>
      <SecondColumnFilter isShowFromComplex={isShowFromComplex} store={store}/>
    </div>

    <div className={styles.formColumn}>
      <ThirdColumnFilters isShowFromComplex={isShowFromComplex} store={store}/>
    </div>
  </>
);
