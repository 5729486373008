import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Params } from 'services';

export const GeneralPlanVillageRender = () => {
  const { complexId, generalPlanId } =
    useParams<Pick<Params, 'complexId' | 'generalPlanId'>>();
  
  return (
    <p>Генеральный план КП {complexId} {generalPlanId }</p>
  );
};

export const GeneralPlanVillage = observer(GeneralPlanVillageRender);
