import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react';
import { Params } from 'services';
import { useIsEditOffer } from './hooks/useIsEditOffer';
import { offerItemStore } from '../../stores';

import { OffersViewContainer } from './containers/OffersViewContainer';
import styles from './styles.module.css';
import { YouAreLookingOfferAsAClient } from './components/YouAreLookingOfferAsAClient';
import { rootProfileStore } from '../../stores/user/RootProfileStore';
import { useIframeMode } from 'hooks';

export const OffersViewPageRender = () => {
  const location = useLocation();
  const { isEditOffer, isOfferPreview } = useIsEditOffer({
    authStore: rootProfileStore.authStore,
  });
  const { hash } = useParams<Pick<Params, 'hash' | 'offerTabs'>>();
  const isIframeMode = useIframeMode()
  const { offer } = offerItemStore;


  useEffect(() => {
    if (!hash) {
      return undefined;
    }
    offerItemStore.currentOfferHash = hash;
    void offerItemStore.fetchOfferByHash({
      hash: offerItemStore.currentOfferHash,
    });

    return () => {
      offerItemStore.currentOfferHash = null;
    };
  }, [hash, location]);
  
  return (
    <div className={styles.wrapper}>
      
      {isOfferPreview && !isIframeMode && (
        <YouAreLookingOfferAsAClient offerId={offer?.id.toString()} />
      )}
      {offer && (
        <div className={styles.content}>
          <OffersViewContainer
            offer={offer}
            isOfferPreview={isOfferPreview}
            isEditOffer={isEditOffer}
            isIframeMode={isIframeMode}
          />
        </div>
      )}
    </div>
  );
};

export const OffersViewPage = observer(OffersViewPageRender);
