import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Text, InputGroup, Input } from 'components/atoms';
import { typeHouseContext } from 'context/TypeHouseProvider';
import styles from './styles.module.css';
import { debounce } from 'lodash';

const InputRow: React.FC<any> = ({ filterKey, ...rest }) => {
  const { villageFilter, handleChangeVillageFilter } = useContext(typeHouseContext);

  const [currentValue, setCurrentValue] = useState(villageFilter[filterKey]);

  useEffect(() => {
    setCurrentValue(villageFilter[filterKey]);
  }, [villageFilter, filterKey]);

  const debouncedChangeHandler = useCallback(
    debounce((name, value) => {
      handleChangeVillageFilter({ target: { name, value } });
    }, 300),
    [handleChangeVillageFilter]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentValue(value);
    debouncedChangeHandler(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <Input
      value={currentValue}
      name={filterKey}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

export const TypeHouseFilters = () => (
  <div className={styles.leftWrapper}>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Площадь дома, м²
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="squareMin" />
        <InputRow placeholder="до" filterKey="squareMax" />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Площадь участка, м²
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="squareLandMin" />
        <InputRow placeholder="до" filterKey="squareLandMax" />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Стоимость, ₽
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="priceMin" />
        <InputRow placeholder="до" filterKey="priceMax" />
      </InputGroup>
    </div>
  </div>
);
