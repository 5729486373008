
export const mapTabs = [
  {
    label: 'Карта',
  },
  {
    label: 'План',
  },
];


export const ComplexPageTestIDs = {
  wrapper: 'ComplexPage_wrapper',
};