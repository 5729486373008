import { action, computed, observable, makeObservable } from 'mobx';
import { RequestPageInfo, SearchParameters, SortByType } from 'services';
import { RequestPageInfoStore } from '../request/RequestPageInfoStore';
import { FilterStore } from '../FilterStore';
import { SearchResultSettingStore } from '../request/SearchResultSettingStore';

type SortDirection = 'asc' | 'desc';

export type ComplexFilterType = {
  notFirstFloor?: boolean;
  notLastFloor?: boolean;
  isLastFloor?: boolean;
  isPenthouse?: boolean;
} & Omit<
  SearchParameters,
  'notFirstFloor' | 'notLastFloor' | 'isLastFloor' | 'isPenthouse' | 'mapBounds'
>;

export class ComplexFilterStore {
  filters: FilterStore<ComplexFilterType, SearchParameters>;

  pageInfo: RequestPageInfoStore;

  searchResultSetting: SearchResultSettingStore;

  constructor() {
    this.filters = new FilterStore<ComplexFilterType, SearchParameters>({});
    this.pageInfo = new RequestPageInfoStore();
    this.searchResultSetting = new SearchResultSettingStore();

    makeObservable(this, {
      filters: observable,
      setSort: action.bound,
      compileFilters: computed,

      // filterTab: observable,
      // currentFilterTab: computed,
      // setFilterTab: action,
    });
  }

  // setFilterTab = (tabIndex: number) => {
  //   this.filterTab = tabIndex;
  // };

  // get currentFilterTab() {
  //   return this.filterTab;
  // }

  setSort(sort?: SortByType, direction?: SortDirection) {
    this.pageInfo.changeSort(sort);
    this.pageInfo.changeSortDirection(direction);
  }

  get compileFilters(): Partial<
    SearchParameters & Omit<RequestPageInfo, 'page' | 'limit'>
  > {
    return {
      ...this.filters.filterToApiFormat,
      ...this.searchResultSetting.toApiFormat,
      ...this.pageInfo.sort,
    };
  }
}

export const complexFilterStore = new ComplexFilterStore();
