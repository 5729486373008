import L, { LatLngTuple, Map as MapType } from 'leaflet';
import {
  action,
  makeObservable,
  observable,
  computed,
  runInAction,
} from 'mobx';
import { filter, isNil, negate } from 'lodash/fp';
import { MapBounds } from 'types/MapBounds';
import { debounce } from 'utils/debounce';
import { MayBe } from '../types';

export class LeafletStoreClass {
  zoom = 12;
  lng: MayBe<number> = null;
  lat: MayBe<number> = null;
  instance: MapType | null = null;
  suppressMoveHandler = false;
  displayedPointsType: "all" | 'complex' | 'developer' = 'all'

  constructor() {
    makeObservable(this, {
      zoom: observable,
      lng: observable,
      lat: observable,
      instance: observable,
      suppressMoveHandler: observable,
      mapBounds: computed,
      isInitialized: computed,
      center: computed,
      setInstance: action.bound,
      updateCenter: action.bound,
      clear: action.bound,
      setZoom: action.bound,
      setSuppressMoveHandler: action.bound,
      setDisplayedPointsType: action.bound
    });
  }

  isInitLatLng = () => filter(negate(isNil))([this.lat, this.lng]).length === 2;

  get center(): LatLngTuple | undefined {
    const center = [this.lat, this.lng];
    if (this.isInitLatLng()) {
      return center as LatLngTuple;
    }
    return undefined;
  }

  get mapBounds(): MapBounds {
    const realBounds = this.instance?.getBounds();

    return {
      east: realBounds?.getEast() || 0,
      north: realBounds?.getNorth() || 0,
      south: realBounds?.getSouth() || 0,
      west: realBounds?.getWest() || 0,
    };
  }

  get isInitialized(): boolean {
    return this.instance !== null;
  }

  setSuppressMoveHandler(value: boolean, duration?: number): void {
    this.suppressMoveHandler = value;

    if (duration) {
      setTimeout(() => {
        runInAction(() => {
          this.suppressMoveHandler = !value;
        });
      }, duration);
    }
  }

  setDisplayedPointsType(type: 'all' | 'complex' | 'developer'): void {
    this.displayedPointsType = type
  }

  updateCenter(latitude?: string, longitude?: string): void {
    if (!this.instance || !longitude || !latitude) {
      return;
    }

    this.lng = parseFloat(longitude);
    this.lat = parseFloat(latitude);
    this.zoom = this.instance.getZoom();
    this.instance.setView([this.lat, this.lng], this.zoom);
  }

  clear() {
    this.zoom = 12;
    this.lng = null;
    this.lat = null;
    this.instance = null;
  }


  setInstance(instance: MapType | null): void {
    if (instance === null) {
      this.instance = null;
      return;
    }

    this.instance = instance;
    this.instance.options.crs = L.CRS.EPSG3395;
    this.instance.invalidateSize();

    this.instance.on(
      'move',
      debounce(() => {
        runInAction(() => {
          if (this.suppressMoveHandler) {
            return; 
          }
          if (this.instance !== null) {
            this.lng = this.instance.getCenter().lng;
            this.lat = this.instance.getCenter().lat;
            this.zoom = this.instance.getZoom();
          }
        });
      }, 250),
    );
  }

  setZoom(newZoom: number): void {
    if (!this.instance) {
      return;
    }

    this.zoom = newZoom;
    this.instance.setZoom(newZoom);
  }
}

export const leafletStore = new LeafletStoreClass();
export default leafletStore;
