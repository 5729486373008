import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';

import { InputGroup } from 'components/atoms';
import { PopupDistrictsChoicer } from 'components/containers/PopupDistrictsChoicer';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';

import styles from '../../AllFilters/AllFilters.module.css';
import { BuildingFiltersStore } from 'stores';

type Props = {
  isShowFromComplex?: boolean,
  store?: BuildingFiltersStore['filters'],
}

export const FirstColumnFilter = memo(({isShowFromComplex, store}:Props ) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;

  return (
    <>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Район</span>
        {districts && (
          <PopupDistrictsChoicer
            data={districts}
            filterKey="districtIds"
            store={complexFilterStore.filters}
            disabled={isLoading || isShowFromComplex}
            placeholder="Любой"
          />
        )}
      </div>

      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Стоимость</span>
        <InputGroup>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="priceMin"
            placeholder="Общая, от"
            className={styles.complexInputLeft}
          />
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="priceMax"
            placeholder="Общая, до"
            className={styles.complexInputRight}
          />
        </InputGroup>
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип дома</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="houseType"
          dictKey="residential_complex_house_type"
          placeholder="Не важно"
          disabled={isShowFromComplex}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип помещения</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          filterKey="roomType"
          placeholder="Любой"
          dictKey="apartment_type_room"
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип квартиры</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          filterKey="rooms"
          placeholder="Любой"
          dictKey="rooms"
        />
      </div>
    </>
  );
});
