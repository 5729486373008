import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L, { ImageOverlay, LatLngBounds, LatLngBoundsExpression } from 'leaflet';
import { useImageLoad } from 'hooks/useImageLoad';
import { MayBe } from 'types';

/**
 * @desc этому коэффициенту соответствует преобразование размера плана этажа в коде админки
 * "Realty\realty-admin\public\js\app.js" - 2651 строка
 * проблема в том что при создании полигонов квартир в админке эти самые полигоны генерируются для планов этажа
 * в 2 раза меньшего размера
 * */
const FLOOR_PLAN_RATIO = 2;

const calcImageOverlayBoundsForFloor = (
  width: number,
  height: number,
): LatLngBoundsExpression => [
  [height, 0],
  [0, width],
];

type Props = {
  imageUrl: MayBe<string>;
  fitBoundsOfPlan?: boolean;
  onChangePlanImage?: (bounds: LatLngBounds) => void;
  children?: (
    imageOverlayLayer: ImageOverlay | undefined,
  ) => ReactElement | null;
};

/**
 * @desc отрисовка плана этажа в leaflet
 * */
export const FloorPlanOverlay = ({
  imageUrl,
  fitBoundsOfPlan,
  children,
  onChangePlanImage,
}: Props) => {

  const map = useMap();
  const floorPlanImage = useImageLoad(imageUrl || undefined);
  const [imageOverlayLayer, setImageOverlayLayer] = useState<
    ImageOverlay | undefined
    >();
  
  const addFloorPlan = useCallback(
    (imageUrl: string, width: number, height: number) => {
      const [imgWidth, imgHeight] = [
        width / FLOOR_PLAN_RATIO,
        height / FLOOR_PLAN_RATIO,
      ];
      return L.imageOverlay(
        imageUrl,
        calcImageOverlayBoundsForFloor(imgWidth, imgHeight),
      ).addTo(map);
    },
    [],
  );

  const removePrevLayers = () => {
    if (imageOverlayLayer) {
      imageOverlayLayer.removeFrom(map);
    }
  };

  useEffect(() => {
    if (imageUrl && floorPlanImage) {
      removePrevLayers();

      const imageOverlay = addFloorPlan(
        imageUrl,
        floorPlanImage.width,
        floorPlanImage.height,
      );
      if (fitBoundsOfPlan) {
        map.fitBounds(imageOverlay.getBounds());
        map.setMaxBounds(imageOverlay.getBounds());
      }
      if (onChangePlanImage) {
        onChangePlanImage(imageOverlay.getBounds());
      }
      setImageOverlayLayer(imageOverlay);
    }
  }, [imageUrl, floorPlanImage, fitBoundsOfPlan]);

  return children ? children(imageOverlayLayer) : null;
};
