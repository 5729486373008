import { action, makeObservable, observable, computed, toJS } from 'mobx';

const COMPLEX_TYPE = ['rc', 'village'];
export class FilterStore<Filter extends object, ApiFilter extends object> {
  initialFilter: Filter = {} as Filter;

  filters: Filter = {} as Filter;

  filterTab = 0;

  constructor(initialFilter: Filter) {
    this.initialFilter = initialFilter;

    makeObservable(this, {
      filters: observable,
      setField: action,
      setFields: action,
      reset: action,
      resetField: action,

      filterTab: observable,
      currentFilterTab: computed,
      setFilterTab: action,
    });
  }

  setFilterTab = (tabIndex: number) => {
    this.filterTab = tabIndex;
    this.filters = this.initialFilter;
    (this.filters as any).type = COMPLEX_TYPE[this.filterTab];
  };

  get currentFilterTab() {
    return this.filterTab;
  }

  getField = <K extends keyof Filter>(key: K): Filter[K] | null =>
    this.filters[key] || null;

  setField<K extends keyof Filter>(key: K, value: Filter[K]) {
    this.filters[key] = value;
    (this.filters as any).type = COMPLEX_TYPE[this.filterTab];
  }

  setFields(filter: Partial<Filter>) {
   
    this.filters = {
      ...this.filters,
      ...filter,
    };
  }

  reset = () => {
    this.filters = this.initialFilter;
  };

  resetField(key: keyof Filter) {
    if (this.filters[key]) {
      (this.filters[key] as any) = null;
    }
  }

  get filterToApiFormat(): Partial<ApiFilter> {
    return Object.keys(this.filters).reduce((acc, key) => {
      let newValue = this.filters[key as keyof Filter] as any;

      if (newValue) {
        if (typeof newValue === 'boolean') {
          newValue = Number(newValue);
        }

        acc = { ...acc, [key]: newValue };
      }

      return acc;
    }, {});
  }
}
