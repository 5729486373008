import {
  floorPlanStore,
  FloorsParams,
} from 'pages/LayoutPage/stores/FloorPlanStore';
import React, { useMemo } from 'react';
import { FloorButton } from '../FloorButton';
import styles from './styles.module.css';
import { get } from 'lodash/fp';
import { Section } from '../../../../../../services';
import { MayBe } from '../../../../../../types';
import { buildingGridStore } from '../../../../stores/buildingGridStore';

type Props = {
  floors: FloorsParams;
  activeFloor: number;
  onChange: (index: number) => (event: any) => void;
};

export const FloorList = ({ activeFloor, floors, onChange }: Props) => {
  const sections = buildingGridStore?.getSections || [];

  const { activeSection } = floorPlanStore;

  const section: MayBe<Section> | undefined = useMemo(
    () => get(activeSection)(sections),
    [activeSection, sections]
  );

  return (
    <div className={styles.floorList}>
      {floors.map(([floorTitle, apartments, isVisible], index) => {
        // Фильтруем квартиры на текущем этаже по активной секции и статусу 6
        const apartmentsInActiveSection = (apartments || []).filter(
          (apartment) => {
            const isInActiveSection =
              get('section_title')(apartment) === get('title')(section);
            const apartmentStatuses = get('catalogs.apartment_status')(
              apartment
            );
            const hasStatus6 =
              Array.isArray(apartmentStatuses) && apartmentStatuses.includes(6);

            return isInActiveSection && hasStatus6;
          }
        );

        return (
          <FloorButton
            key={floorTitle}
            onClick={onChange(index)}
            title={floorTitle}
            subTitle={apartmentsInActiveSection.length.toString()}
            isActive={activeFloor === index}
            isVisible={isVisible}
          />
        );
      })}
    </div>
  );
};