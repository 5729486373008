import { useCallback } from 'react';
import {
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { flattenDeep, flow, map, slice, uniqBy } from 'lodash/fp';
import { toJS } from 'mobx';
import { filterSuggestionByCities } from 'services/v1/rc/suggest/handlers';
import { useSuggestOnChange } from 'pages/Main/containers/SearchAndFilterBar/components/SuggestByAddressComplexesAndDevelopers/hooks/useSuggestOnChange';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { suggestStore } from 'stores/SuggestStore/SuggestStore';
import { AutoSuggestOption } from 'components/atoms';
import {
  isSuggestComplex,
  searchService,
  SuggestComplex,
  SuggestDeveloper,
  SuggestYandex,
} from 'services';
import { cityStore } from 'stores/CityStore';
import { leafletStore } from 'stores';

type Props = {
  displayFirstElements?: number;
};

export const useSuggestByAddressComplexesAndDevelopers = (props?: Props) => {
  const { searchValue, setSearchValue, onChange, resetFilter } = useSuggestOnChange({
    filterStore: complexFilterStore.filters,
    suggestStore,
  });

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: SuggestionsFetchRequestedParams) => {
      suggestStore.suggestByAllSource(value);
    },
    [suggestStore],
  );

  const onSuggestionsClearRequested = useCallback(() => null, []);

  const onSuggestionSelected = useCallback(
    (
      _,
      data: SuggestionSelectedEventData<
        AutoSuggestOption<SuggestComplex | SuggestDeveloper | SuggestYandex>
      >,
    ) => {
      const suggestFilter = suggestStore.getFilterBySelectedSuggestion(
        data.suggestion,
      );
      resetFilter();

      // если был выбран ЖК или КП - сразу обновляем центр карты, так как здесь сразу приходят координаты
      if (data.suggestion.payload && isSuggestComplex(data.suggestion.payload)) {
        leafletStore.updateCenter(
          data.suggestion.payload.latitude ?? '',
          data.suggestion.payload.longitude ?? '',
        ); 
        leafletStore.setDisplayedPointsType('complex')
        leafletStore.setSuppressMoveHandler(true)
      } else {
        // если был выбран девелопер - устанавливаем только тип отображемых точек
        leafletStore.setDisplayedPointsType('developer')
        if (!leafletStore.suppressMoveHandler) {
          leafletStore.setSuppressMoveHandler(true)
        }
      }

      suggestStore.clear();
      if (suggestFilter) {
        Object.entries(suggestFilter).forEach(([key, value]: any) => {
          complexFilterStore.filters.setField(key, value);
        });
      }
    },
    [suggestStore, searchService],
  );

  const suggestions = flow(
    map(map(toJS)),
    map(uniqBy('label')),
    map(slice(0, props?.displayFirstElements || 3)),
    flattenDeep,
  )([
    suggestStore.complexes,
    filterSuggestionByCities(cityStore.cities)(suggestStore.yandex),
    suggestStore.developers,
  ]) as unknown as AutoSuggestOption<
    SuggestComplex | SuggestDeveloper | SuggestYandex
    >[];
  
  return {
    searchValue,
    setSearchValue,
    onChange,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionSelected,
    onSuggestionsClearRequested,
  };
};
