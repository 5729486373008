import { ComplexAlbum } from "services";
import { getCountConstructionProgressAlbums, getCountPhotoAlbums } from "services/v1/rc/album/helpers";
import { ComplexTabs } from "./types";


export const getComplexTabs = (fotos: ComplexAlbum[] | undefined): ComplexTabs => {
  return {
    complexInfo: { title: 'Информация о ЖК', show: true },
    saleOccasions: { title: 'Акции', show: false },
    news: { title: 'Новости', show: false },
    near: { title: 'Поблизости', show: true },
    benefits: { title: 'Преимущества', show: true },
    apartments: { title: 'Квартиры', show: false },
    albums: {
      title: 'Фотографии',
      show: getCountPhotoAlbums(fotos) > 0,  // Показывать, если есть фотоальбомы
    },
    constructionAlbums: {
      title: 'Ход строительства',
      show: getCountConstructionProgressAlbums(fotos) > 0,  // Показывать, если есть альбомы с ходом строительства
    },
    documents: { title: 'Документы и сделка', show: true },
    sellPoints: { title: 'Отделы продаж', show: false },
  };
};

export const getVillageTabs = (fotos: ComplexAlbum[] | undefined): ComplexTabs => {
  return {
    complexInfo: { title: 'Информация о КП', show: true },
    houses: { title: 'Дома', show: true },
    benefits: { title: 'Преимущества', show: true },
    albums: {
      title: 'Фотографии',
      show: getCountPhotoAlbums(fotos) > 0,  // Показывать, если есть фотоальбомы
    },
    documents: { title: 'Документы и сделка', show: true },
    sellPoints: { title: 'Отделы продаж', show: true },
  };
}