import React, { MouseEventHandler, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, Button, ButtonGroup } from 'components/atoms';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { useAllFiltersPopup } from 'components/containers/AllFiltersPopup';
import { SuggestByAddressComplexesAndDevelopers } from './components/SuggestByAddressComplexesAndDevelopers';
import { PriceFilter } from './components/PriceFilter/PriceFilter';
import { RoomsСottagesFilter } from './components/RoomsСottagesFilter';
import { FilterButtonWithFilterCount } from './components/FilterButtonWithFilterCount';
import styles from './SmallFilters.module.css';
import { useSuggestOnChange } from './components/SuggestByAddressComplexesAndDevelopers/hooks/useSuggestOnChange';
import { suggestStore } from 'stores/SuggestStore/SuggestStore';
import { leafletStore } from 'stores/LeafletStore';

export const SearchAndFilterBarRender = () => {
  const [isPriceVisible, setIsPriceVisible] = useState(false);
  const handlePriceVisibility = useCallback(() => {
    setIsPriceVisible(!isPriceVisible);
  }, [isPriceVisible]);
  const closePriceVisibility: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsPriceVisible(false);
  };

    const { setSearchValue } = useSuggestOnChange({
    filterStore: complexFilterStore.filters,
    suggestStore,
  });


  const [isFlatsQuantityVisible, setIsFlatsQuantityVisible] = useState(false);
  const handleFlatsQuantityVisible = useCallback(() => {
    setIsFlatsQuantityVisible(!isFlatsQuantityVisible);
  }, [isFlatsQuantityVisible]);

  const { showAllFiltersPopup } = useAllFiltersPopup('main');
  const maxRoomsInViewport = 0;
  const roomButtonLabel = 'ЖК, КП';

  const firstRoomsItems =
    infoStore?.dictionaries?.rooms?.items &&
    infoStore.getItemsBeforeIndex(
      infoStore?.dictionaries?.rooms?.items,
      maxRoomsInViewport,
    );
  
  return (
    <div className={styles.scrollBarWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <div className={styles.searchWrapper}>
            <SuggestByAddressComplexesAndDevelopers />
          </div>
          <ButtonGroup>
            <>
              {firstRoomsItems?.map(({ id, value }) => (
                <Button
                  key={value}
                  variant={
                    complexFilterStore.filters
                      .getField('rooms')
                      ?.includes(String(id))
                      ? 'primary'
                      : 'secondary'
                  }
                  onClick={() => {
                    let rooms = complexFilterStore.filters.getField('rooms');
                    if (Array.isArray(rooms)) {
                      if (rooms.includes(String(id))) {
                        rooms = rooms.filter((item) => item !== String(id));
                      } else {
                        rooms.push(String(id));
                      }
                    } else {
                      rooms = [String(id)];
                    }
                    complexFilterStore.filters.setField('rooms', rooms);
                  }}
                >
                  {value}
                </Button>
              ))}
            </>
            <DropdownButton
              className={styles.roomWrapper}
              onVisibleChange={handleFlatsQuantityVisible}
              visible={isFlatsQuantityVisible}
              overlayContent={RoomsСottagesFilter({ maxRoomsInViewport })}
            >
              {roomButtonLabel}
            </DropdownButton>
          </ButtonGroup>
          <DropdownButton
            className={styles.priceWrapper}
            onVisibleChange={handlePriceVisibility}
            visible={isPriceVisible}
            overlayContent={PriceFilter({ closePriceVisibility })}
          >
            ₽
          </DropdownButton>
        </div>

        {/* // на главной странице */}
        <div className={styles.otherButtons}>
          <FilterButtonWithFilterCount
            onFilterReset={() => {
              complexFilterStore.filters.reset();
              setSearchValue('');
              leafletStore.setDisplayedPointsType('all');
              leafletStore.setSuppressMoveHandler(false)
            }}
            onFilterOpen={showAllFiltersPopup}
            resultedFilters={complexFilterStore.filters.filterToApiFormat}
          />
        </div>
      </div>
    </div>
  );
};

export const SearchAndFilterBar = observer(SearchAndFilterBarRender);
