import React from 'react';

import { FirstColumnFilter } from '../Search/villages/FirstColumnFilters';
import { SecondColumnFilter } from '../Search/villages/SecondColumnFilters';
import { ThirdColumnFilters } from '../Search/villages/ThirdColumnFilters';

import styles from './AllFilters.module.css';
import { ContextValueVillageFilters } from 'context';

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"]
}

export const VillageTab = ({ isShowFromVillage, store, handleChangeStore }: Props) => {
  return <>
    <div className={styles.formColumn}>
      <FirstColumnFilter
        isShowFromVillage={isShowFromVillage}
        store={store}
        handleChangeStore={handleChangeStore} />
    </div>

    <div className={styles.formColumn}>
      <SecondColumnFilter isShowFromVillage={isShowFromVillage}  store={store}
        handleChangeStore={handleChangeStore} />
    </div>

    <div className={styles.formColumn}>
      <ThirdColumnFilters
        store={store}
        handleChangeStore={handleChangeStore}
      />
    </div>
  </>
};
